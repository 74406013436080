"use client";

import { useEffect, useState } from "react";
import "./css/calc.scss";
import PackageUsageInfo from "@/components/PackageUsageInfo/PackageUsageInfo";
import { formatPackageEventItem } from "@/helpers/formatters";
import { gtmSender } from "@/helpers";

export default function CalcDGS({ data }: {
  readonly data: Record<string, any>
}) {

  const [activeIndex, setActiveIndex] = useState<any>(0);
  const [on, setOn] = useState(false);

  const [diplomaCheck, setDiplomaCheck] = useState(false);
  const [diplomaPuan, setDiplomaPuan] = useState<any>(75);

  const [sozelPuan, setSozelPuan] = useState<any>(0);
  const [sayisalPuan, setSayisalPuan] = useState<any>(0);
  const [esitAgirlikPuan, setEsitAgirlikPuan] = useState<any>(0);
  const [netScores, setNetScores] = useState<any[]>([]);

  const [result, setResult] = useState<Record<string,any>>({});

  const onHandleCorrectChange = (
    numberOfQuest: any,
    lessonName: any,
    coefficient: any,
    lessonType: any,
    id: any,
    { target: { value } }: any,
  ) => {
    setResult({
      ...result,
      [id]: {
        ...result[id],
        numberOfQuest: numberOfQuest,
        lessonName: lessonName,
        coefficient: coefficient,
        lessonType: lessonType,
        correct: Number(value),
      },
    });
  };

  const onHandleWrongChange = (
    numberOfQuest: any,
    lessonName: any,
    coefficient: any,
    lessonType: any,
    id: any,
    { target: { value } }: any,
  ) => {
    setResult({
      ...result,
      [id]: {
        ...result[id],
        numberOfQuest: numberOfQuest,
        lessonName: lessonName,
        coefficient: coefficient,
        lessonType: lessonType,
        wrong: Number(value),
      },
    });
  };

  const totalScore = () => {
    setOn(!on);

    let sozel = 0;
    let sayisal = 0;

    for (const key in result) {
      if (Object.hasOwnProperty.call(result, key)) {
        const element = result[key];

        if (element.correct === undefined) {
          // Eğer kullanıcı doğru sayısını girmeden hesapla butonuna basarsa
          element.correct = 0;
        }

        if (element.wrong === undefined) {
          // Eğer kullanıcı yanlış sayısını girmeden hesapla butonuna basarsa
          element.wrong = 0;
        }

        const items = element?.lessonType?.split(",");
        const net = (element.correct - element.wrong * element.coefficient);

        if (items.includes('sozel')) {
          sozel += net;
        }
        if (items.includes('sayisal')) {
          sayisal += net;
        }

        setNetScores((netScores) => [
          ...netScores,
          {
            value: element.correct - element.wrong * element.coefficient, // Net puanı hesaplıyoruz
            lessonName: element.lessonName, // Ders adını ekliyoruz
            lessonTime: element.numberOfQuest, // Dersin kaç saat olduğunu ekliyoruz
          },
        ]);
      }
    }
    const sozelPuan = sozel * 2.55443 + sayisal * 0.44557 + (diplomaPuan * (diplomaCheck ? 0.45 : 0.6)) + 126.59568;
    const sayisalPuan = sozel * 0.51088 + sayisal * 2.619845833 + (diplomaPuan * (diplomaCheck ? 0.45 : 0.6)) + 144.15323;
    const esitAgirlikPuan = sozel * 1.53266 + sayisal * 1.5719075 + (diplomaPuan * (diplomaCheck ? 0.45 : 0.6)) + 135.87445;

    setSozelPuan(sozelPuan.toFixed(3));
    setSayisalPuan(sayisalPuan.toFixed(3));
    setEsitAgirlikPuan(esitAgirlikPuan.toFixed(3));

    const scrollDuration = 300;
    const scrollStep = (-1 * (window?.scrollY ?? 0)) / (scrollDuration / 15),
      scrollInterval = setInterval(function () {
        if (window?.scrollY !== 0) {
          window?.scrollBy(0, scrollStep);
        } else clearInterval(scrollInterval);
      }, 15);
  };

  const resetHandler = () => {
    setOn(false);
    setResult({});
    setSozelPuan(0);
    setSayisalPuan(0);
    setEsitAgirlikPuan(0);
    setActiveIndex(0);
    setDiplomaPuan(75);
    setNetScores([]);
  };

  const accordionClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function accordionNext() {
    if (activeIndex !== data.items.length - 1) {
      setActiveIndex(activeIndex + 1);
      const nextAccordionId = `accordion-${activeIndex}`;
      const nextAccordion: any = document?.getElementById(nextAccordionId);
      nextAccordion?.scrollIntoView({ behavior: "smooth" });
    } else {
      setActiveIndex(0);
    }
  }

  useEffect(() => {
    if(on && data?.package?.id) {
      const eventVal: Record<string,any> = {
        "event": "view_item",
        "ecommerce": {
          "currency": "TRY",
          "items": [ formatPackageEventItem(data.package) ]
        }
      };

      gtmSender({ ecommerce: null });
      gtmSender(eventVal);
    }

    const dgsColor = data.card_bg_color;
    const rangeSlider = document?.querySelectorAll(".rangeSlider");

    rangeSlider?.forEach((slider) => {
      const thumbSize = 18;
      const range: any = slider.querySelector("input[type=range]");
      const bubble: any = slider.querySelector(".tooltip");
      const ratio = (range.value - range.min) / (range.max - range.min);
      let amountToMove =
        ratio * (range.offsetWidth - thumbSize - thumbSize) + thumbSize;

      bubble.style.left = amountToMove + "px";
      range.style.background = `linear-gradient(to right,${dgsColor} ${amountToMove}px, #e5e7eb ${amountToMove}px)`;
    });
  }, [
    result,
    sozelPuan,
    sayisalPuan,
    esitAgirlikPuan,
    diplomaPuan,
    diplomaCheck,
    activeIndex,
    netScores,
    on,
    data.card_bg_color,
    data.package,
  ]);

  return (
    <>
      <div className="relative mb-16">
        <div className="relative">
          <div className="relative">
            <h1 className="text-3xl font-semibold mb-5">DGS Puan Hesaplama</h1>

            {on ? (
              <div
                className={`accordion mb-4 p-3 pb-6 bg-[white] rounded-lg`}
              >
                <button
                  className={`bg-scoreCardColor flex w-full justify-between items-center rounded-lg px-4 py-6 text-left text-2xl font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                >
                  DGS Puan Hesaplama Sonucu
                  <div
                    onClick={resetHandler}
                    className="order-last cursor-pointer rounded-lg bg-white text-charcoal hover:text-white hover:bg-charcoal px-4 w-fit h-10 flex items-center justify-center text-lg"
                  >
                    Tekrar Hesapla
                  </div>
                </button>
                <div className="totalScore">
                  <div className="flex mt-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      DGS Sözel Ağırlık Puanı
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#fd6585] to-[#0d25b9]">
                      {sozelPuan}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      DGS Sayisal Ağırlık Puanı
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#49f4e3] to-[#167fba]">
                      {sayisalPuan}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      DGS Eşit Ağırlık Puanı
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#fd6585] to-[#0d25b9]">
                      {esitAgirlikPuan}
                    </div>
                  </div>

                  {
                    data.package?.id && (
                      <div className="mb-10">
                        <PackageUsageInfo
                          data={data.package}
                          packagesSlug={data.packages_slug}
                        />
                      </div>
                    )
                  }

                  {
                    netScores?.length > 0 ? (
                      <div className="netSayısı">
                        <button
                          className={`bg-scoreCardColor mt-4 flex w-full justify-between items-center rounded-lg px-4 py-6 text-left text-2xl font-bold text-white focus:outline-none focus-visible:ringfocus-visible:ring-opacity-75`}
                        >
                          Netleriniz
                          <div
                            onClick={resetHandler}
                            data-testid='resetHandler'
                            className="order-last cursor-pointer rounded-lg bg-white text-charcoal hover:text-white hover:bg-charcoal px-4 w-fit h-10 flex items-center justify-center text-lg"
                          >
                            Tekrar Hesapla
                          </div>
                        </button>
                        {netScores.map((item: any) => {
                          if (netScores) {
                            return (
                              <div className="flex mt-4" key={item.lessonName + '-' + item.lessonTime + '-' + item.value}>
                                <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-16 rounded-l-lg bg-alice-blue font-bold text-xl text-charcoal">
                                  {item.lessonName}
                                  <span className="text-slate-400 text-base font-bold ml-1">
                                    ({item.lessonTime} Soru)
                                  </span>
                                </div>
                                <div className="scoreTotal clip-path-two flex items-center justify-end pr-10 w-2/5 h-16 font-bold text-xl rounded-r-lg text-charcoal bg-gradient-from-tl bg-alice-blue">
                                  {item.value} Net
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </div>
            ) : (
              <>
                {data.items.map((lesson: Record<string,any>, index: number) => {
                  if (lesson?.name === "Diploma Puanı") {
                    return (
                      <div
                        id={`accordion-${index}`}
                        key={`lesson${lesson.id}`}
                        className={`accordion mb-4 p-3 bg-[white] rounded-lg`}>
                        <button
                          className={`bg-scoreCardColor flex w-full justify-between items-center rounded-lg px-4 py-4 text-left text-lg font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                          onClick={() => accordionClick(index)}
                          data-testid="accordionClick"
                        >
                          Diploma Puanı
                          <span
                            className="order-last rounded-lg bg-white text-charcoal px-4 w-fit h-10 flex items-center justify-center"
                          >
                            {diplomaPuan} Puan
                          </span>
                        </button>
                        <>
                          {index === activeIndex && (
                            <div>
                              <div className="grid-cols-1 align-middle justify-center pt-10 pb-5 px-3">
                                <label
                                  htmlFor="diplomaPuanı"
                                  className="rangeSlider relative block mb-[90px] text-lg font-semibold text-charcoal dark:text-charcoal text-center"
                                >
                                  Önce diploma puanınızı seçiniz
                                  <input
                                    id="diplomaPuanı"
                                    type="range"
                                    data-testid="diplomaPuanı"
                                    value={diplomaPuan}
                                    className="rangeCalc scoreCardRange w-full h-8 mt-4 
                                    bg-gray-200 rounded-full 
                                    appearance-none cursor-pointer"
                                    min={50}
                                    max={100}
                                    onChange={(e) => setDiplomaPuan(e.target.value)}
                                  />
                                  <div
                                    className={`tooltip bubble absolute 
                                    bottom-[-3.6rem] flex h-[3rem] w-[3rem] 
                                    -translate-x-1/2 items-center justify-center 
                                    rounded-tl-[0] rounded-tr-[50%] rounded-br-[50%] 
                                    rounded-bl-[50%] rotate-45 bg-scoreCardColor 
                                    align-middle text-body-medium text-charcoal
                                    text-xl`}
                                  >
                                    <div className="-rotate-45 text-sm font-bold text-white">
                                      {diplomaPuan}
                                    </div>
                                  </div>
                                </label>
                                <label
                                  className="relative block
                                  text-md font-semibold text-charcoal 
                                  dark:text-charcoal text-md text-center"
                                >
                                  <div className="w-full text-lg font-semibold">
                                    Geçen sene bir bölüme yerleştin mi?
                                  </div>
                                  <input
                                    className="w-7 h-7 mt-4"
                                    type="checkbox"
                                    data-testid="checkBox"
                                    checked={diplomaCheck}
                                    onChange={() => setDiplomaCheck(!diplomaCheck)}
                                  />
                                </label>
                              </div>
                              <div className="flex gap-4 justify-center">
                                <button
                                  onClick={accordionNext}
                                  data-testid="nextButton"
                                  className={`w-1/2 md:w-1/4 lg:w-1/5 bg-blue-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                                >
                                  İleri
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        id={`accordion-${index}`}
                        key={`lesson${lesson.id}`}
                        className={`accordion mb-4 p-3 bg-[white] rounded-lg`}
                      >
                        <button
                          className={` flex w-full justify-between items-center rounded-lg px-4 py-4 text-left text-lg font-bold text-white bg-scoreCardColor focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75`}
                          onClick={() => accordionClick(index)}
                          data-testid="accordionClick2"
                        >
                          {lesson.name}
                          <span className="order-last rounded-lg bg-white text-charcoal px-4 w-fit h-10 flex items-center justify-center">
                            {lesson.question_count} Soru
                          </span>
                        </button>
                        {index === activeIndex && (
                          <div
                            key={lesson.id}
                            className="grid-cols-1 align-middle justify-center pt-10 pb-5 px-3"
                          >
                            <div className="grid-cols-1">
                              <label
                                htmlFor="tytPuanHesaplama"
                                className="rangeSlider relative block mb-[110px] text-lg font-semibold text-charcoal dark:text-charcoal text-md text-center"
                              >
                                Doğru Sayısını Seçiniz
                                <input
                                  id="tytPuanHesaplama"
                                  data-testid="tytPuanHesaplama"
                                  type="range"
                                  value={result[lesson.id]?.correct ?? 0}
                                  className="w-full h-8 mt-4 
                                bg-gray-200 rounded-full 
                                appearance-none cursor-pointer
                                rangeCalc scoreCardRange"
                                  max={
                                    lesson.question_count -
                                    (result[lesson.id]?.wrong ?? 0)
                                  }
                                  onChange={(e) =>
                                    onHandleCorrectChange(
                                      lesson.question_count,
                                      lesson.name,
                                      lesson.coefficient,
                                      lesson.lesson_type,
                                      lesson.id,
                                      e
                                    )
                                  }
                                />
                                <div
                                  className={`tooltip bubble absolute 
                                  bottom-[-3.6rem] flex h-[3rem] w-[3rem] 
                                  -translate-x-1/2 items-center justify-center 
                                  rounded-tl-[0] rounded-tr-[50%] rounded-br-[50%] 
                                  rounded-bl-[50%] rotate-45 bg-scoreCardColor 
                                  align-middle text-body-medium text-charcoal
                                  text-xl`}
                                >
                                  <div className="-rotate-45 text-sm font-bold text-white">
                                    {result[lesson.id]?.correct ?? 0}
                                  </div>
                                </div>
                              </label>

                              <label
                                htmlFor="tytPuanHesaplama2"
                                className="rangeSlider relative block mb-[80px] text-md font-semibold text-charcoal dark:text-charcoal text-lg text-center"
                              >
                                Yanlış Sayısını Seçiniz
                                <input
                                  id="tytPuanHesaplama2"
                                  data-testid="tytPuanHesaplama2"
                                  type="range"
                                  value={result[lesson.id]?.wrong ?? 0}
                                  className="w-full h-8 mt-4 bg-gray-200 
                                  rounded-full appearance-none cursor-pointer 
                                  rangeCalc scoreCardRange"
                                  max={
                                    lesson.question_count -
                                    (result[lesson.id]?.correct ?? 0)
                                  }
                                  onChange={(e) =>
                                    onHandleWrongChange(
                                      lesson.question_count,
                                      lesson.name,
                                      lesson.coefficient,
                                      lesson.lesson_type,
                                      lesson.id,
                                      e
                                    )
                                  }
                                />
                                <div
                                  className={`tooltip bubble absolute bottom-[-3.6rem] 
                                  flex h-[3rem] w-[3rem] -translate-x-1/2 items-center 
                                  justify-center rounded-tl-[0] rounded-tr-[50%] 
                                  rounded-br-[50%] rounded-bl-[50%] rotate-45 
                                  bg-scoreCardColor align-middle text-body-medium text-charcoal
                                  text-xl`}
                                >
                                  <div className="-rotate-45 text-sm font-bold text-white">
                                    {result[lesson.id]?.wrong ?? 0}
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div className="flex gap-4 justify-center">
                              <button
                                onClick={accordionNext}
                                className={activeIndex === data.items.length - 1 ? 'hidden' : `w-1/2 md:w-1/4 lg:w-1/5 bg-blue-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                              >
                                İleri
                              </button>
                              <button
                                onClick={totalScore}
                                data-testid="totalButton"
                                className={`w-1/2 md:w-1/4 lg:w-1/5 bg-red-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                              >
                                Hesapla
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        input[type=range].scoreCardRange::-webkit-slider-thumb {
          background: ${data.card_bg_color};
        }
        .bg-scoreCardColor {
          background-color: ${data.card_bg_color};
        }
      `}</style>
    </>
  );
}
