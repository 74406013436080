"use client";

import { useEffect, useState } from "react";
import "./css/calc.scss";
import PackageUsageInfo from "@/components/PackageUsageInfo/PackageUsageInfo";
import { formatPackageEventItem } from "@/helpers/formatters";
import { gtmSender } from "@/helpers";

export default function CalcYDT({ data }: {
  readonly data: Record<string, any>
}) {

  const [activeIndex, setActiveIndex] = useState(0);
  const [ydtOn, setydtOn] = useState(false);

  const [ydtDiplomaCheck, setydtDiplomaCheck] = useState(false);
  const [ydtDiplomaPuan, setydtDiplomaPuan] = useState<any>(75);

  const [ydtHamPuan, setydtHamPuan] = useState<any>(0);
  const [ydtYerlestirmePuan, setydtYerlestirmePuan] = useState<any>(0);
  const [netScores, setNetScores] = useState<any[]>([]);
  const [dilHamPuan, setDilHamPuan] = useState<any>(0);
  const [dilYerlestirmePuan, setDilYerlestirmePuan] = useState<any>(0);
  const [ydtResult, setydtResult] = useState<Record<string,any>>({});

  const onHandleydtCorrectChange = (
    numberOfQuest: any,
    lessonName: any,
    lessonType: any,
    coefficient: any,
    id: any,
    { target: { value } }: any
  ) => {
    setydtResult({
      ...ydtResult,
      [id]: {
        ...ydtResult[id],
        numberOfQuest: numberOfQuest,
        correct: Number(value),
        coefficient: coefficient,
        lessonName: lessonName,
        lessonType: lessonType,
      },
    });
  };

  const onHandleydtWrongChange = (
    numberOfQuest: any,
    lessonName: any,
    lessonType: any,
    coefficient: any,
    id: any,
    { target: { value } }: any
  ) => {
    setydtResult({
      ...ydtResult,
      [id]: {
        ...ydtResult[id],
        numberOfQuest: numberOfQuest,
        wrong: Number(value),
        coefficient: coefficient,
        lessonName: lessonName,
        lessonType: lessonType,
      },
    });
  };

  const totalScore = () => {
    setydtOn(!ydtOn);
    let tyt: any = 0;
    let lang: any = 0;

    for (const key in ydtResult) {
      if (Object.hasOwnProperty.call(ydtResult, key)) {
        const element = ydtResult[key];

        if (element.correct === undefined) {
          // Eğer kullanıcı doğru sayısını girmeden hesapla butonuna basarsa
          element.correct = 0;
        }

        if (element.wrong === undefined) {
          // Eğer kullanıcı yanlış sayısını girmeden hesapla butonuna basarsa
          element.wrong = 0;
        }
        const items = element.lessonType?.split(",") ?? [];
        const coefficient = (element.correct - element.wrong * 0.25) * element.coefficient;

        if (items.includes('YAB') || element.lessonName === 'Yabancı Dil') { // YAB = YABANCI DİL
          lang += coefficient;
        } else {
          tyt += coefficient;
        }
        setNetScores((netScores) => [
          ...netScores,
          {
            value: element.correct - element.wrong * 0.25, // Net puanı hesaplıyoruz
            lessonName: element.lessonName, // Ders adını ekliyoruz
            lessonTime: element.numberOfQuest, // Dersin kaç saat olduğunu ekliyoruz
          },
        ]);
      }
    }

    const ydtHamPuan = 100 + tyt;
    const ydtYerlestirmePuan = 100 + tyt + ydtDiplomaPuan * (ydtDiplomaCheck ? 0.3 : 0.6);
    
    setydtHamPuan(ydtHamPuan.toFixed(3)); // Ham puanı hesaplıyoruz ve virgülden sonra 3 basamak gösteriyoruz
    setydtYerlestirmePuan(ydtYerlestirmePuan.toFixed(3)); // Yerleştirme puanını hesaplıyoruz ve virgülden sonra 3 basamak gösteriyoruz
    setDilHamPuan((100 + lang + (tyt * 0.4)).toFixed(3));
    setDilYerlestirmePuan((100 + lang + (tyt * 0.4) + (ydtDiplomaPuan * (ydtDiplomaCheck ? 0.3 : 0.6))).toFixed(3));

    const scrollDuration = 300;
    const scrollStep = (-1 * (window?.scrollY ?? 0))/ (scrollDuration / 15),
      scrollInterval = setInterval(function () {
        if (window?.scrollY !== 0) {
          window?.scrollBy(0, scrollStep);
        } else clearInterval(scrollInterval);
      }, 15);
  };

  const resetHandler = () => {
    setydtOn(false);
    setydtResult({});
    setydtHamPuan(0);
    setydtYerlestirmePuan(0);
    setActiveIndex(0);
    setydtDiplomaPuan(75);
    setNetScores([]);
  };

  const accordionClick = (index: any) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  function accordionNext() {
    if (activeIndex !== data.items.length - 1) {
      setActiveIndex(activeIndex + 1);

      const nextAccordionId = `accordion-${activeIndex}`;
      const nextAccordion = document?.getElementById(nextAccordionId);
      nextAccordion?.scrollIntoView({ behavior: "smooth" });
    } else {
      setActiveIndex(0);
    }
  }

  useEffect(() => {
    if(ydtOn && data?.package?.id) {
      const eventVal: Record<string,any> = {
        "event": "view_item",
        "ecommerce": {
          "currency": "TRY",
          "items": [ formatPackageEventItem(data.package) ]
        }
      };

      gtmSender({ ecommerce: null });
      gtmSender(eventVal);
    }

    const ydtColor = data.card_bg_color;

    const rangeSlider = document?.querySelectorAll(".rangeSlider");

    rangeSlider?.forEach((slider) => {
      const thumbSize = 18;
      const range: any = slider.querySelector("input[type=range]");
      const bubble: any = slider.querySelector(".tooltip");
      const ratio = (range.value - range.min) / (range.max - range.min);
      let amountToMove =
        ratio * (range.offsetWidth - thumbSize - thumbSize) + thumbSize;

      bubble.style.left = amountToMove + "px";
      range.style.background = `linear-gradient(to right,${ydtColor} ${amountToMove}px, #e5e7eb ${amountToMove}px)`;
    });
  }, [
    ydtResult,
    ydtHamPuan,
    ydtYerlestirmePuan,
    ydtDiplomaPuan,
    ydtDiplomaCheck,
    activeIndex,
    netScores,
    data.card_bg_color,
    data.package,
    ydtOn
  ]);

  return (
    <>
      <div className="relative mb-16">
        <div className="relative">
          <h1 className="text-3xl font-semibold mb-5">{ data.caption }</h1>

          <div className="relative">

            {ydtOn ? (
              <div
                className={`accordion mb-4 p-3 pb-6 bg-[white] rounded-lg`}
              >
                <button
                  className={`bg-scoreCardColor flex w-full justify-between items-center rounded-lg px-4 py-6 text-left text-2xl font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                >
                  YDT Puan Hesaplama Sonuçları
                  <div
                    onClick={resetHandler}
                    className="order-last cursor-pointer rounded-lg bg-white text-charcoal hover:text-white hover:bg-charcoal px-4 w-fit h-10 flex items-center justify-center text-lg"
                  >
                    Tekrar Hesapla
                  </div>
                </button>
                <div className="totalScore">
                  <div className="flex mt-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      TYT Ham Puan
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#fd6585] to-[#0d25b9]">
                      {ydtHamPuan}
                    </div>
                  </div>
                  <div className="flex mt-4 mb-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      TYT Yerleştirme Puan
                    </div>
                    <div className="scoreTotal yerlestirmePuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-from-tl bg-gradient-to-br from-[#fd6585] to-[#0d25b9]">
                      {ydtYerlestirmePuan}
                    </div>
                  </div>
                  <div className="flex mt-4">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      Yabancı Dil Ham Puan
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#fd6585] to-[#0d25b9]">
                      {dilHamPuan}
                    </div>
                  </div>

                  <div className="flex mt-4 mb-8">
                    <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-24 rounded-l-lg bg-charcoal font-bold text-2xl text-white">
                      Yabancı Dil Yerleştirme Puan
                    </div>
                    <div className="scoreTotal hamPuan clip-path-two flex items-center justify-center w-2/5 h-24 font-bold text-4xl rounded-r-lg text-white bg-gradient-to-r from-[#49f4e3] to-[#167fba]">
                      {dilYerlestirmePuan}
                    </div>
                  </div>
                  {
                    data.package?.id && (
                      <div className="mb-10">
                        <PackageUsageInfo
                          data={data.package}
                          packagesSlug={data.packages_slug}
                        />
                      </div>
                    )
                  }

                  {
                    netScores?.length > 0 && (
                      <div className="netSayısı">
                        <button
                          className={`bg-scoreCardColor mt-4 flex w-full justify-between items-center rounded-lg px-4 py-6 text-left text-2xl font-bold text-white focus:outline-none focus-visible:ringfocus-visible:ring-opacity-75`}
                        >
                          Netleriniz
                          <div
                            onClick={resetHandler}
                            data-testid='resetHandler'
                            className="order-last cursor-pointer rounded-lg bg-white text-charcoal hover:text-white hover:bg-charcoal px-4 w-fit h-10 flex items-center justify-center text-lg"
                          >
                            Tekrar Hesapla
                          </div>
                        </button>
                        {netScores.map((item) => {
                          if (netScores) {
                            return (
                              <div className="flex mt-4" key={item.lessonName + '-' + item.lessonTime + '-' + item.value}>
                                <div className="scoreTotalText clip-path-one flex items-center justify-start pl-10 w-3/5 h-16 rounded-l-lg bg-alice-blue font-bold text-xl text-charcoal">
                                  {item.lessonName}
                                  <span className="text-slate-400 text-base font-bold ml-1">
                                    ({item.lessonTime} Soru)
                                  </span>
                                </div>
                                <div className="scoreTotal clip-path-two flex items-center justify-end pr-10 w-2/5 h-16 font-bold text-xl rounded-r-lg text-charcoal bg-gradient-from-tl bg-alice-blue">
                                  {item.value} Net
                                </div>
                              </div>
                            );
                          }
                        })}
                      </div>
                    )
                  }
                </div>
              </div>
            ) : (
              <>
                {data.items.map((lesson: Record<string,any>, index: number) => {
                  if ( lesson?.name === "Diploma Puanı") {
                    return(
                      <div 
                        id={`accordion-${index}`}
                        key={`diploma-${lesson.id}`}
                        className={`accordion mb-4 p-3 bg-[white] rounded-lg`}>
                        <button
                          className={`bg-scoreCardColor flex w-full justify-between items-center rounded-lg px-4 py-4 text-left text-lg font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-opacity-75`}
                          onClick={() => accordionClick(index)}
                          data-testid="accordionClick"
                        >
                          Diploma Puanı
                          <span className="order-last rounded-lg bg-white text-charcoal px-4 w-fit h-10 flex items-center justify-center" >
                            {ydtDiplomaPuan} Puan
                          </span>
                        </button>

                        <>
                          {index === activeIndex && (
                            <div>
                              <div className="grid-cols-1 align-middle justify-center pt-10 pb-5 px-3">
                                <label
                                  htmlFor="diplomaPuanı"
                                  className="rangeSlider relative block mb-[90px] text-lg font-semibold text-charcoal dark:text-charcoal text-center"
                                >
                                  Önce diploma puanınızı seçiniz
                                  <input
                                    id="diplomaPuanı"
                                    data-testid="diplomaPuanı"
                                    type="range"
                                    value={ydtDiplomaPuan}
                                    className={`rangeCalc scoreCardRange w-full h-8 mt-4 
                                    bg-gray-200 rounded-full 
                                    appearance-none cursor-pointer`}
                                    min={50}
                                    max={100}
                                    onChange={(e) => setydtDiplomaPuan(e.target.value)}
                                  />
                                  <div
                                    className={`tooltip bubble absolute 
                                    bottom-[-3.6rem] flex h-[3rem] w-[3rem] 
                                    -translate-x-1/2 items-center justify-center 
                                    rounded-tl-[0] rounded-tr-[50%] rounded-br-[50%] 
                                    rounded-bl-[50%] rotate-45
                                    align-middle text-body-medium text-charcoal
                                    text-xl bg-scoreCardColor`}
                                  >
                                    <div className="-rotate-45 text-sm font-bold text-white">
                                      {ydtDiplomaPuan}
                                    </div>
                                  </div>
                                </label>
                                <label
                                  className="relative block
                                  text-md font-semibold text-charcoal 
                                  dark:text-charcoal text-md text-center"
                                >
                                  <div className="w-full text-lg font-semibold">
                                    Geçen sene bir bölüme yerleştin mi?
                                  </div>
                                  <input
                                    className="w-7 h-7 mt-4"
                                    type="checkbox"
                                    data-testid="checkBox"
                                    checked={ydtDiplomaCheck}
                                    onChange={() => setydtDiplomaCheck(!ydtDiplomaCheck)}
                                  />
                                </label>
                              </div>
                              <div className="flex gap-4 justify-center">
                                <button
                                  onClick={accordionNext}
                                  data-testid="nextButton"
                                  className={`w-1/2 md:w-1/4 lg:w-1/5 bg-blue-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                                >
                                  İleri
                                </button>
                              </div>
                            </div>
                          )}
                        </>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        id={`accordion-${index}`}
                        key={lesson.id}
                        className={`accordion mb-4 p-3 bg-[white] rounded-lg`}
                      >
                        <button
                          className={`bg-scoreCardColor flex w-full justify-between items-center rounded-lg px-4 py-4 text-left text-lg font-bold text-white focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75`}
                          onClick={() => accordionClick(index)}
                          data-testid="accordionClick2"
                        >
                          {lesson.name}
                          <span className="order-last rounded-lg bg-white text-charcoal px-4 w-fit h-10 flex items-center justify-center">
                            {lesson.question_count} Soru
                          </span>
                        </button>
                        {index === activeIndex && (
                          <div
                            key={lesson.id}
                            className="grid-cols-1 align-middle justify-center pt-10 pb-5 px-3"
                          >
                            <div className="grid-cols-1">
                              <label
                                htmlFor="ydtPuanHesaplama"
                                className="rangeSlider relative block mb-[110px] text-lg font-semibold text-charcoal dark:text-charcoal text-md text-center"
                              >
                                Doğru Sayısını Seçiniz
                                <input
                                  id="ydtPuanHesaplama"
                                  data-testid="ydtPuanHesaplama"
                                  type="range"
                                  value={ydtResult[lesson.id]?.correct ?? 0}
                                  className={`w-full h-8 mt-4 
                                  bg-gray-200 rounded-full 
                                  appearance-none cursor-pointer
                                  rangeCalc scoreCardRange`}
                                  max={
                                    lesson.question_count -
                                  (ydtResult[lesson.id]?.wrong ?? 0)
                                  }
                                  onChange={(e) =>
                                    onHandleydtCorrectChange(
                                      lesson.question_count,
                                      lesson.name,
                                      lesson.lessont_type,
                                      lesson.coefficient,
                                      lesson.id,
                                      e
                                    )
                                  }
                                />
                                <div
                                  className={`bg-scoreCardColor tooltip bubble absolute 
                                  bottom-[-3.6rem] flex h-[3rem] w-[3rem] 
                                  -translate-x-1/2 items-center justify-center 
                                  rounded-tl-[0] rounded-tr-[50%] rounded-br-[50%] 
                                  rounded-bl-[50%] rotate-45
                                  align-middle text-body-medium text-charcoal
                                  text-xl`}
                                >
                                  <div className="-rotate-45 text-sm font-bold text-white">
                                    {ydtResult[lesson.id]?.correct ?? 0}
                                  </div>
                                </div>
                              </label>

                              <label
                                htmlFor="ydtPuanHesaplama2"
                                className="rangeSlider relative block mb-[80px] text-md font-semibold text-charcoal dark:text-charcoal text-lg text-center"
                              >
                                Yanlış Sayısını Seçiniz
                                <input
                                  id="ydtPuanHesaplama2"
                                  data-testid="ydtPuanHesaplama2"
                                  type="range"
                                  value={ydtResult[lesson.id]?.wrong ?? 0}
                                  className={`w-full h-8 mt-4 bg-gray-200 
                                  rounded-full appearance-none cursor-pointer 
                                  rangeCalc scoreCardRange`}
                                  max={
                                    lesson.question_count -
                                  (ydtResult[lesson.id]?.correct ?? 0)
                                  }
                                  onChange={(e) =>
                                    onHandleydtWrongChange(
                                      lesson.question_count,
                                      lesson.name,
                                      lesson.lesson_type,
                                      lesson.coefficient,
                                      lesson.id,
                                      e
                                    )
                                  }
                                />
                                <div
                                  className={`bg-scoreCardColor tooltip bubble absolute bottom-[-3.6rem] 
                                  flex h-[3rem] w-[3rem] -translate-x-1/2 items-center 
                                  justify-center rounded-tl-[0] rounded-tr-[50%] 
                                  rounded-br-[50%] rounded-bl-[50%] rotate-45 
                                  align-middle text-body-medium text-charcoal
                                  text-xl`}
                                >
                                  <div className="-rotate-45 text-sm font-bold text-white">
                                    {ydtResult[lesson.id]?.wrong ?? 0}
                                  </div>
                                </div>
                              </label>
                            </div>
                            <div className="flex gap-4 justify-center">
                              <button
                                onClick={accordionNext}
                                className={activeIndex === data.items.length - 1 ? 'hidden' : `w-1/2 md:w-1/4 lg:w-1/5 bg-blue-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                              >
                                İleri
                              </button>
                              <button
                                onClick={totalScore}
                                data-testid="totalButton"
                                className={`w-1/2 md:w-1/4 lg:w-1/5 bg-red-500 text-white text-lg font-semibold rounded-lg py-2 px-6`}
                              >
                                Hesapla
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    );
                  }
                })}
              </>
            )}
          </div>
        </div>
      </div>

      <style jsx>{`
        input[type=range].scoreCardRange::-webkit-slider-thumb {
          background: ${data.card_bg_color};
        }
        .bg-scoreCardColor {
          background-color: ${data.card_bg_color};
        }
      `}</style>
    </>
  );
}
